import { Card, Modal } from "react-bootstrap";
import { Image, Steps } from "antd";
import CloseModalTab from "../../component/Nav/CloseModalTab";

const { Step } = Steps;
const VoucherDetailModal = ({
  show,
  setShow,
  voucherDetails,
  redeemDetails,
}) => {
  return (
    <Modal fullscreen={true} show={show}>
      <Modal.Body className="w-100 p-2 mt-3">
        <CloseModalTab onclick={() => setShow(false)} />
        <Card
          style={{
            backgroundColor: "#FEA965",
            height: "200px",
            boxShadow: "0px 4px 20px rgba(254, 169, 101, 0.5)",
            borderRadius: "15px",
            marginTop: "20px",
          }}
        >
          <div
            style={{
              borderRadius: "15px",
              display: "flex",
              marginTop: "10px",
              justifyContent: "space-between",
            }}
          >
            <p
              style={{
                fontSize: "20px",
                color: "#fff",
                marginLeft: "20px",
                marginTop: "15px",
              }}
            >
              {voucherDetails?.ProductServiceName}
            </p>
            <Card.Img
              src={voucherDetails?.ImageUrl}
              style={{
                width: "64px",
                height: "64px",
                backgroundColor: "#FFCA9F",
                marginRight: "20px",
              }}
            />
          </div>
          <p
            style={{
              color: "#fff",
              marginLeft: "20px",
              marginTop: "10px",
            }}
          >{`Earn ${voucherDetails?.Point} points to Redeem this voucher`}</p>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Card.Text
              style={{
                marginLeft: "20px",
                marginTop: "5px",
                color: "#fff",
                fontWeight: "500",
                fontSize: "16px",
              }}
            >
              {voucherDetails?.ProductServiceName}
            </Card.Text>
          </div>
        </Card>
        <Image
          rootClassName="d-flex flex-columns justify-content-center mt-3"
          width="100%"
          src={voucherDetails?.ImageUrl}
          height={150}
        />
        <Steps progressDot className="mt-4" direction="vertical" current={1}>
          {redeemDetails &&
            redeemDetails?.data?.map((item, index) => (
              <Step
                key={index}
                title={item?.TextValue}
                description={<img style={{width:"100px", height:"100%"}} src={item?.Images} />}
              />
            ))}
        </Steps>
      </Modal.Body>
    </Modal>
  );
};

export default VoucherDetailModal;
