import { useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
const ThankYou = props => {
  const { show, setShow, quizData, correctAnsCount } = props;

  useEffect(() => {
    if (show) {
      const timer = setTimeout(() => {
        window.location.href = quizData?.Url;
      }, 4000);

      return () => clearTimeout(timer);
    }
  }, [show]);

  return (
    <div>
      <Modal
        style={{ fontFamily: "Rubik" }}
        fullscreen={true}
        show={show}
        onHide={() => setShow(false)}
      >
        <Modal.Body
          style={{
            background: "white",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            textAlign: "center",
            color: "black",
          }}
        >
          {correctAnsCount !== 0 ? (
            <>
              <h2 style={{ fontSize: "40px", fontWeight: "bold" }}>
                🎉 Hurray 🎉
              </h2>
              <div style={{ fontSize: "25px", fontWeight: "bold" }}>
                Score : {correctAnsCount}/{quizData?.CntQuestions}
              </div>
            </>
          ) : (
            <h4 style={{ fontSize: "25px", fontWeight: "bold" }}>
              😞 Better Luck Next Time 😞
            </h4>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ThankYou;
