import React, { useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import BottomTab from "./BottomTab";
import close from "/assets/images/svgicon/close.svg";
import HomePage from "./HomePage";
import Activity from "./Activity";


const Quiz = () => {
  const [selected, setSelected] = useState("home");
  return (
    <div>
      <div style={{ padding: "0px" }}>
        <Row className="p-10" style={{ width: "100%" }}>
          <Col
            className="text-left nav-title pt-1"
            xs={6}
            style={{ color: "#8D69F9", fontSize: "20px", fontWeight: "700" }}
          >
            {selected === "home" ? "" : "Activity"}
          </Col>
          <Col className="text-end" xs={{ span: 1, offset: 5 }}>
            <img
              onClick={() => {
                setSelected("home");
              }}
              src={close}
              alt="close page"
              style={{ marginRight: " 21px", width: "14px", marginTop: "10px" }}
            />
          </Col>
        </Row>
      </div>
      <div style={{ marginBottom: "40px" }}>
        {selected === "home" && <HomePage />}
        {selected === "activity" && <Activity />}
      </div>
      <div>
        <BottomTab selected={selected} setSelected={setSelected} />
      </div>
    </div>
  );
};

export default Quiz;
