import { useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
const FailedMessage = props => {
  const { show, setShow, message } = props;

  return (
    <div>
      <Modal style={{ fontFamily: "Rubik" }} fullscreen={true} show={show} onHide={() => setShow(false)}>
        <Modal.Body
          style={{
            background: "white",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            textAlign: "center",
            color: "black",
          }}
        >
          {message.length > 0 ? (
            <>
              <h2 style={{ fontSize: "24px", fontWeight: "bold" }}>
                {message}
              </h2>
            </>
          ) : (
            <>
              <h2 style={{ fontSize: "24px", fontWeight: "bold" }}>
                Something went wrong!
              </h2>
            </>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default FailedMessage;
