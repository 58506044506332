import { Modal, Row, Col, Image } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Button, Form, FormGroup, Input, Label, Spinner } from "reactstrap";
import { AiFillCheckCircle } from "react-icons/ai";
import { asyncWrap } from "../../utils/utils";
import axios from "axios";
import Swal from "sweetalert2";
import { useAuth } from "../../context/auth-context";
import { BsFillCameraFill } from "react-icons/bs";
import { imageResize } from "../../utils/imageResize";

const PanCardModal = ({ show, setShow }) => {
  const [panCardNumber, setPanCardNumber] = useState("");
  const [panCardVerify, setPanCardVerify] = useState(false);
  const [panCardImageUrl, setPanCardImageUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPanImage, setShowPanImage] = useState(false);
  const [disableUploadbtn, setDisableUploadbtn] = useState(false);
  const [kycVerify, setKycVerify] = useState(false);
  const [panVerify, setPanVerify] = useState(false);
  const [kycAutomaticVerify, setKycAutomaticVerify] = useState(false);
  const history = useHistory();
  const { user } = useAuth();

  const maxSize = 10 * 1024 * 1024;

  useEffect(() => {
    getUserProfile();
  }, []);

  const getUserProfile = async () => {
    const [error, result] = await asyncWrap(axios.get("/userDetails"));
    if (error?.response?.status === 401) {
      Swal.fire("Please Logout and login again");
      return;
    }
    if (!result) {
      console.log(error);
      return;
    } else {
      if (result.data.data?.IsPanVerified) {
        setKycVerify(true);
      } else {
        const [configError, configResult] = await asyncWrap(
          axios.get("config")
        );
        if (configError) {
          console.log(configError);
        } else {
          if (
            configResult.data.data.filter(
              item => item.FlagName === "KYC Automatic verification"
            )[0].Value === "true"
          ) {
            setKycAutomaticVerify(true);
          } else {
            setShowPanImage(true);
          }
        }
      }
      setPanCardNumber(result.data.data?.PancardNumber);
      setPanCardImageUrl(result.data.data?.PancardFrontimage);
    }
  };

  const handlePanCardImageUpload = async e => {
    const file = e.target.files[0];
    if (file && file.size >= maxSize) {
      Swal.fire("File size exceeds the limit (10MB)");
      return;
    }
    setDisableUploadbtn(true);
    const formData = new FormData();
    const newFile = await imageResize(e.target.files[0]);
    formData.append("file", newFile);
    const [error, result] = await asyncWrap(axios.post("/upload", formData));
    if (error) {
      Swal.fire(error.response.data.message);
      return;
    }
    const {
      data: { fileUrl },
    } = result;
    setPanCardImageUrl(fileUrl);
    // setShowPanImage(false);
    setDisableUploadbtn(false);
  };

  const handleVerifyPanCard = async () => {
    setLoading(true);
    let pancardpattern = new RegExp(/[A-Z]{5}[0-9]{4}[A-Z]{1}/);
    if (!pancardpattern.test(panCardNumber)) {
      Swal.fire("Please enter valid pan number");
      setLoading(false);
      return;
    }

    const [panError, panResult] = await asyncWrap(
      axios.post("/verifyKyc/verify-pan", {
        panNumber: panCardNumber,
      })
    );

    if (!panResult) {
      Swal.fire(panError.response.data.data.message);
      setLoading(false);
      return;
    }
    if (!panResult.data.data.valid) {
      Swal.fire(panError.response.data.data.message);
      setLoading(false);
      return;
    }
    setLoading(false);
    setPanCardVerify(true);
    setPanVerify(true);
    handlePanCardVerifySubmit();
    // setShowPanImage(true);
  };

  const handlePanCardVerifySubmit = async () => {
    if (panCardNumber === "") {
      Swal.fire("Please enter Pan card no");
      return;
    }
    const [error, result] = await asyncWrap(
      axios.post("/editProfile", {
        userType: user?.profile?.roleName || user?.roleName,
        kycTypeId: "Pan Card",
        userId: user?.profile?.userId || user.userId,
        panNumber: panCardNumber,
        kycNumber: panCardNumber,
        kycPhotoPath: panCardImageUrl,
        pancardFront: panCardImageUrl,
        ispanverify: 1,
      })
    );
    if (!result) {
      Swal.fire(error.response.data.data.message);
      history.replace("/home");
    } else if (result.data?.success) {
      history.replace("/home");
    } else {
      Swal.fire(result?.data?.message).then(() => {
        window.location.reload();
      });
    }
  };

  const handleUpdateDetail = async () => {
    if (panCardNumber === "") {
      Swal.fire("Please enter Pan card no");
      return;
    }
    const [error, result] = await asyncWrap(
      axios.post("/editProfile", {
        userType: user?.profile?.roleName || user?.roleName,
        kycTypeId: "Pan Card",
        userId: user?.profile?.userId || user.userId,
        panNumber: panCardNumber,
        kycNumber: panCardNumber,
        kycPhotoPath: panCardImageUrl,
        pancardFront: panCardImageUrl,
      })
    );
    if (!result) {
      Swal.fire(error.response.data.data.message);
      history.replace("/home");
    } else if (result.data?.success) {
      history.replace("/home");
    } else {
      Swal.fire(result?.data?.message).then(() => {
        window.location.reload();
      });
    }
  };

  return (
    <Modal fullscreen={true} show={show}>
      {/* <Modal.Header> */}
      {/* <SecoundaryHeader
          text="Pan Card Detail"
          sideHide="false"
          onBack={() => {
            history.push("/home");
            setShow(false);
          }}
        /> */}
      {/* </Modal.Header> */}
      <Modal.Body
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          marginTop: "50px",
        }}
      >
        <Row style={{ width: "100%", marginLeft: "auto", marginRight: "auto" }}>
          <Col style={{ display: "flex", flexDirection: "row", width: "100%" }}>
            <Form className="w-100">
              <FormGroup floating className="p-0">
                <Input
                  readOnly={kycVerify || panVerify}
                  style={{ border: "1px solid #B0B0B0", width: "100%" }}
                  required
                  onChange={e => setPanCardNumber(e.target.value)}
                  value={panCardNumber}
                  placeholder="Enter Pan Card Number"
                  type="text"
                />
                <Label>Pan Card Number</Label>
              </FormGroup>
            </Form>

            <div
              style={{
                width: "15%",
                display: "flex",
                justifyContent: "center",
                alignItems: "baseline",
              }}
            >
              <AiFillCheckCircle
                style={{
                  height: "auto",
                  width: "60%",
                  marginTop: "12px",
                  color: `${panCardVerify || kycVerify ? "green" : "black"}`,
                }}
              />
            </div>
          </Col>
        </Row>
        {showPanImage && !kycVerify ? (
          <Row className="w-100">
            <Col>
              <Label style={{ width: "100%" }}>
                Pan Card Image
                <div
                  // onClick={() => {
                  //   document.getElementById("aadharCardImage")?.click();
                  // }}
                  style={{
                    background: "#E5ECFD",
                    height: "150px",
                    borderRadius: "10px",
                  }}
                  className="mt-2 mb-3"
                >
                  <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                    <BsFillCameraFill
                      style={{
                        color: "#3A69DD",
                        width: "40px",
                        height: "40px",
                      }}
                    />
                  </div>
                </div>
                <Input
                  hidden
                  required
                  max="1048576"
                  accept=".jpeg,.jpg,.png"
                  id="panCardImage"
                  onChange={e => handlePanCardImageUpload(e)}
                  placeholder="Please Upload Pancard Image"
                  style={{ width: "100%" }}
                  type="file"
                />
              </Label>
            </Col>
          </Row>
        ) : null}
        {panCardImageUrl ? (
          <Row>
            <Col>
              <Image src={panCardImageUrl} width="100%" height="200px" />
            </Col>
          </Row>
        ) : null}
        {!kycVerify ? (
          <Row
            style={{
              background: "#FAFAFA",
              padding: "10px",
              margin: "10px 0px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {showPanImage ? (
              <Col className="p-0" style={{ color: "#CC7E02" }}>
                <Button
                  disabled={disableUploadbtn}
                  style={{
                    width: "max-content",
                    background: `${disableUploadbtn ? "#ccc" : "#3A69DD"}`,
                    color: "white",
                    border: "none",
                    padding: "0.6rem 2rem",
                    marginTop: "0.5rem",
                    fontWeight: "600",
                    borderRadius: "20px",
                  }}
                  onClick={() => {
                    handleUpdateDetail();
                  }}
                >
                  {disableUploadbtn ? (
                    <Spinner
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    "Submit"
                  )}
                </Button>
              </Col>
            ) : (
              <Col className="p-0" style={{ color: "#CC7E02" }}>
                <Button
                  style={{
                    width: "max-content",
                    background: "#3A69DD",
                    color: "white",
                    border: "none",
                    padding: "0.6rem 2rem",
                    marginTop: "0.5rem",
                    fontWeight: "600",
                    borderRadius: "20px",
                  }}
                  onClick={e => {
                    handleVerifyPanCard(e);
                  }}
                  disabled={loading}
                >
                  {loading ? (
                    <Spinner
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    "Pancard Verify"
                  )}
                </Button>
              </Col>
            )}
          </Row>
        ) : (
          ""
        )}
      </Modal.Body>
    </Modal>
  );
};

export default PanCardModal;
