import PanCardModal from "../component/KycVerify/PanCardModal";
import AadharCardModal from "../component/KycVerify/AadharCardModal";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import CloseModalTab from "../component/Nav/CloseModalTab";
import GstinCardModal from "../component/KycVerify/GstinCardModal";
import { useAuth } from "../context/auth-context";

const KycVerify = () => {
  // eslint-disable-next-line
  const [show, setShow] = useState(true);
  const [showPanCardModal, setShowPanCardModal] = useState(false);
  const [showAadharCardModal, setShowAadharCardModal] = useState(false);
  const [showGstinCardModal, setShowGstinCardModal] = useState(false);
  const { user } = useAuth();

  return (
    <>
      <PanCardModal show={showPanCardModal} setShow={setShowPanCardModal} />
      <AadharCardModal
        show={showAadharCardModal}
        setShow={setShowAadharCardModal}
      />
      <GstinCardModal
        show={showGstinCardModal}
        setShow={setShowGstinCardModal}
      />
      <Modal fullscreen={true} show={show}>
        {/* <Modal.Header> */}
        {/* <SecoundaryHeader
            text={t("kyc_verification")}
            sideHide="false"
            onBack={() => {
              history.push("/home");
              setShow(false);
            }}
          /> */}
        {/* </Modal.Header> */}
        <Modal.Body
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            marginTop: "50px",
          }}
        >
          <div className="d-flex flex-column gap-4">
            <CloseModalTab />
            <div
              onClick={() => {
                setShowAadharCardModal(true);
              }}
              className="d-flex flex-column shadow p-3 rounded-4 text-center"
            >
              <div
                style={{
                  color: "rgb(58, 105, 221)",
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
              >
                Aadhar Card Verification
              </div>
              <img
                style={{ width: "100%" }}
                alt="aadharcard"
                src="/assets/images/kyc/aadharimg.jpg"
              />
            </div>
            {(user?.profile?.roleName || user?.roleName) === "Retailer" ? (
              <div
                onClick={() => {
                  setShowGstinCardModal(true);
                }}
                className="d-flex flex-column shadow p-3 rounded-4 text-center"
              >
                <div
                  style={{
                    color: "rgb(58, 105, 221)",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  GSTIN Verification
                </div>
                <img
                  style={{ width: "100%" }}
                  alt="aadharcard"
                  src="/assets/images/kyc/gstinimg.jpg"
                />
              </div>
            ) : (
              ""
            )}
            <div
              onClick={() => {
                setShowPanCardModal(true);
              }}
              className="d-flex flex-column shadow p-3 rounded-4 text-center"
            >
              <div
                style={{
                  color: "rgb(58, 105, 221)",
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
              >
                Pan Card Verification
              </div>
              <img
                style={{ width: "100%", borderRadius: "10px" }}
                alt="pancard"
                src="/assets/images/kyc/pancard.png"
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default KycVerify;
