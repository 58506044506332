import React, { useEffect, useState, useContext, createRef } from "react";
import {
  Modal,
  Row,
  Col,
  ProgressBar,
  Card,
  Button,
  Image,
} from "react-bootstrap";
import { FiPause, FiPlay } from "react-icons/fi";
import readloud from "/assets/images/Quiz/readloud.svg";
import sound from "/assets/images/Quiz/music.svg";
import AnswerPage from "./AnswerPage";
import LeaderboardAfterQuiz from "./LeaderBoardAfterQuiz";
import { asyncWrap } from "../../utils/utils";
import axios from "axios";
import { UsersPointsData } from "../../context/QuizContext";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import moment from "moment";
import Swal from "sweetalert2";
import audioFile1 from "/assets/audio/correct.mp3";
import audioFile2 from "/assets/audio/wrong.mp3";
import ThankYou from "./ThankYou";
import FailedMessage from "./FailedMessage";

const StartQuiz = () => {
  const { t } = useTranslation();
  const [questions, setQuestions] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [active, setActive] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [correctAnswer, setCorrectAnswer] = useState();
  const [showThankYou, setShowThankYou] = useState(false);
  const [showAnsscreen, setShowAnsScreen] = useState(false);

  const [correctAnsCount, setCorrectAnsCount] = useState(0);
  const [quizData, setQuizData] = useState([]);
  const [ansQuestions, setAnsQuestions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [experienceStartDate, setExperienceStartDate] = useState("");
  const [userQuizData, setUserQuizData] = useState([]);
  const [failedModelShow, setFailedModelShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { id } = useParams();
  const [apiParams, setApiParams] = useState({});
  const queryParameters = new URLSearchParams(window.location.search);
  const ClientId = queryParameters.get("clientId");
  const expName = queryParameters.get("expName");
  const code = queryParameters.get("code");
  const property = queryParameters.get("property");
  const [userId, setUserId] = useState("");

  useEffect(async () => {
    if (ClientId && expName && code && property) {
      setApiParams({
        clientId: ClientId,
        expName: expName,
        code: code,
        property: property,
      });

      const [error, result] = await asyncWrap(
        axios.post("/bajaj/check-user", {
          clientId: ClientId,
          quizId: id,
        })
      );
      if (!result) {
        setErrorMessage(error.response?.data?.message);
        setFailedModelShow(true);
        // console.log(error.response?.data?.message);
        return;
      }
      if (result.data?.success) {
        setApiParams({
          clientId: ClientId,
          expName: expName,
          code: code,
          property: property,
        });
        setQuizData(result.data?.data[0]);
        getQuestions(result.data?.data[0]?.QuizID);
        setExperienceStartDate(moment());
        setUserId(result.data.userId);
      } else {
        setErrorMessage(result.data.message);
        setFailedModelShow(true);
        // console.log(result.data);
      }
    } else {
      setErrorMessage("Client Id Required");
      setFailedModelShow(true);
    }
  }, [ClientId, expName, code, property]);

  let correctAudio = new Audio(audioFile1);
  let wrongAudio = new Audio(audioFile2);

  var newtime;
  const getQuestions = async quizId => {
    const [error, result] = await asyncWrap(
      axios.get(`quiz-questions?quizId=${quizId}`)
    );
    if (!result) {
      console.log(error);
    }
    setQuestions(result.data.data);
    setLoading(false);
  };

  const checkAnswerQuestion = async (quizQuestionId, questionSelectAns) => {
    setSelectedOption(questionSelectAns);
    const answer = questions.filter(
      item => item.QuizQuestionID === quizQuestionId
    );
    if (answer[0].CorrectAnswer === questionSelectAns) {
      correctAudio.play();

      setCorrectAnswer(true);
      setAnsQuestions([
        ...ansQuestions,
        {
          id: answer[0]?.QuizQuestionID,
          correctAns: true,
        },
      ]);
      setUserQuizData([
        ...userQuizData,
        {
          quizId: id,
          QuizQuestionID: answer[0]?.QuizQuestionID,
          points: answer[0]?.PointValue,
          answerStatus: true,
          userAnswer: questionSelectAns,
        },
      ]);
    } else {
      wrongAudio.play();
      setCorrectAnswer(false);
      setAnsQuestions([
        ...ansQuestions,
        {
          id: answer[0]?.QuizQuestionID,
          correctAns: false,
        },
      ]);
      setUserQuizData([
        ...userQuizData,
        {
          quizId: id,
          QuizQuestionID: answer[0]?.QuizQuestionID,
          points: answer[0]?.PointValue,
          answerStatus: false,
          userAnswer: questionSelectAns,
        },
      ]);
    }

    if (questions.length === currentQuestion + 1) {
      const analyticsData = {
        experienceCompletionFlag: true,
        experienceLaunchDateTime: moment(experienceStartDate).format(
          "DD-MM-YYYY HH:mm:ss"
        ),
        experienceCompleteDateTime: moment().format("DD-MM-YYYY HH:mm:ss"),
        experienceDuration: moment().diff(
          moment(experienceStartDate),
          "seconds"
        ),
        numberOfQuestions: quizData.CntQuestions,
        correctAnswers:
          answer[0].CorrectAnswer === questionSelectAns
            ? correctAnsCount + 1
            : correctAnsCount,
        correctAnswerFlag: {
          questions: [
            ...ansQuestions,
            {
              id: answer[0].QuizQuestionID,
              correctAns:
                answer[0].CorrectAnswer === questionSelectAns ? true : false,
            },
          ],
        },
      };
      const [error, result] = await asyncWrap(
        axios.post("/bajaj/submit", {
          clientId: apiParams.clientId,
          analytics: analyticsData,
          expName: apiParams.expName,
          userId,
          questionsData: [
            ...userQuizData,
            {
              quizId: id,
              QuizQuestionID: answer[0].QuizQuestionID,
              points: answer[0]?.PointValue,
              answerStatus:
                answer[0].CorrectAnswer === questionSelectAns ? true : false,
              userAnswer: questionSelectAns,
            },
          ],
        })
      );
      if (!result) {
        console.log(error);
        return;
      }
      if (result?.data?.success) {
        const timeoutId = setTimeout(() => {
          setShowThankYou(true);
          setActive(false);
          setShowAnsScreen(false);
        }, 4000);
      }
    }
    const timeoutId = setTimeout(() => {
      if (questions.length !== currentQuestion + 1) {
        setCurrentQuestion(currentQuestion + 1);
      }
      if (answer[0].CorrectAnswer === questionSelectAns) {
        setCorrectAnsCount(correctAnsCount + 1);
      }
      setActive(false);
      setShowAnsScreen(false);
    }, 4000);
  };

  const toggle = position => {
    if (active === position) {
      setShowAnsScreen(true);
      setActive(null);
    } else {
      setShowAnsScreen(true);
      setActive(position);
    }
  };

  const myColor = (position, option) => {
    // if (ansQuestions[currentQuestion])
    if (active === position) {
      if (
        selectedOption &&
        questions[currentQuestion].CorrectAnswer === selectedOption
      ) {
        return "green"; // Set the color to green for correct answer
      } else {
        return "red"; // Set the color to red for wrong answer

        // User selected the wrong answer
      }
    } else {
      if (
        showAnsscreen &&
        questions[currentQuestion].CorrectAnswer === option
      ) {
        console.log(option);
        return "green";
      }
    }
    return quizData?.OptionColor || "#8D69F9";
  };

  function isURL(str) {
    const urlPattern = /^(https?:\/\/)?([a-zA-Z0-9.-]+\.[a-zA-Z]{2,})(:[0-9]+)?(\/[^\s]*)?$/;
    return urlPattern.test(str);
  }

  const myColorStrip = position => {
    if (ansQuestions && currentQuestion > position) {
      // User has answered this question
      return ansQuestions[position].correctAns ? "green" : "red";
    } else {
      // User has not answered this question yet
      return "gray";
    }
  };

  return (
    <>
      <ThankYou
        quizData={quizData}
        correctAnsCount={correctAnsCount}
        show={showThankYou}
        setShow={setShowThankYou}
      />
      <FailedMessage
        show={failedModelShow}
        setShow={setFailedModelShow}
        message={errorMessage}
      />
      {loading ? (
        <div className="quiz-loader-main">
          <div id="quiz-loader"></div>
        </div>
      ) : (
        <Modal
          style={{ fontFamily: "Rubik" }}
          fullscreen={true}
          show={true}
          onHide={() => setShow(false)}
        >
          <Modal.Body style={{
            backgroundImage: quizData?.BackgroundImage ? `url(${quizData?.BackgroundImage})` : '', backgroundRepeat: "no-repeat",
            backgroundSize: "cover"
          }}>
            <div
              style={{ marginLeft: "4px", marginRight: "4px", }}
              className="d-flex flex-row justify-content-between"
            >
              <div style={{ fontSize: "22px" }}>Q {currentQuestion + 1}</div>
              <div style={{ fontSize: "22px" }}>
                Score {correctAnsCount}/{questions.length}
              </div>
            </div>
            <div className="d-flex flex-row justify-content-around mt-2">
              {questions.map((item, index) => (
                <div
                  key={index}
                  style={{
                    height: "8px",
                    width: `${320 / questions.length}px`,
                    background: myColorStrip(index),
                    borderRadius: "10px",
                  }}
                ></div>
              ))}
            </div>

            {questions.length === 0 && (
              <>
                <Row style={{ marginTop: "20px" }}>
                  <Col>
                    <Card
                      style={{
                        height: "200px",
                        borderRadius: "20px",
                        backgroundColor: "#CEBDFF",
                      }}
                    >
                      <Card.Body
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          textAlign: "center",
                        }}
                      >
                        <p
                          style={{
                            color: "#000000",
                            fontSize: "20px",
                            fontWeight: "500",
                          }}
                        >
                          {t("something_wrong")}
                        </p>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </>
            )}
            {questions.length !== 0 && currentQuestion <= questions.length ? (
              <>
                <Row style={{ marginTop: "20px" }}>
                  <Col>
                    <Card
                      style={{
                        height: "auto",
                        borderRadius: "10px",
                        backgroundColor: quizData?.QuestionColor || "#CEBDFF",
                        padding: "15px",
                      }}
                    >
                      <Card.Body
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          textAlign: "center",
                        }}
                      >
                        <p
                          style={{
                            color: quizData?.FontColor || "#000000",
                            fontSize: "20px",
                            fontWeight: "500",
                            marginBottom: "0px",
                          }}
                        >
                          {questions[currentQuestion].Question}
                        </p>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
                {!isURL(questions[currentQuestion].Option1) ? (
                  <>
                    <Row style={{ marginTop: "20px" }}>
                      <Col
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Button
                          disabled={showAnsscreen}
                          onClick={() => {
                            toggle(0);
                            checkAnswerQuestion(
                              questions[currentQuestion].QuizQuestionID,
                              questions[currentQuestion].Option1
                            );
                          }}
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            padding: "10px",
                            fontSize: "20px",
                            color: quizData?.FontColor || "#000",
                            background: myColor(
                              0,
                              questions[currentQuestion].Option1
                            ),
                          }}
                        >
                          {questions[currentQuestion].Option1}
                        </Button>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "10px" }}>
                      <Col
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Button
                          disabled={showAnsscreen}
                          onClick={() => {
                            toggle(1);
                            checkAnswerQuestion(
                              questions[currentQuestion].QuizQuestionID,
                              questions[currentQuestion].Option2
                            );
                          }}
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            padding: "10px",
                            fontSize: "20px",
                            color: quizData?.FontColor || "#000",
                            background: myColor(
                              1,
                              questions[currentQuestion].Option2
                            ),
                          }}
                        >
                          {questions[currentQuestion].Option2}
                        </Button>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "10px" }}>
                      <Col
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Button
                          disabled={showAnsscreen}
                          onClick={() => {
                            toggle(2);
                            checkAnswerQuestion(
                              questions[currentQuestion].QuizQuestionID,
                              questions[currentQuestion].Option3
                            );
                          }}
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            fontSize: "20px",
                            padding: "10px",
                            color: quizData?.FontColor || "#000",
                            background: myColor(
                              2,
                              questions[currentQuestion].Option3
                            ),
                          }}
                        >
                          {questions[currentQuestion].Option3}
                        </Button>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "10px" }}>
                      <Col
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Button
                          disabled={showAnsscreen}
                          onClick={() => {
                            toggle(3);
                            checkAnswerQuestion(
                              questions[currentQuestion].QuizQuestionID,
                              questions[currentQuestion].Option4
                            );
                          }}
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            fontSize: "20px",
                            padding: "10px",
                            color: quizData?.FontColor || "#000",
                            background: myColor(
                              3,
                              questions[currentQuestion].Option4
                            ),
                          }}
                        >
                          {questions[currentQuestion].Option4}
                        </Button>
                      </Col>
                    </Row>
                  </>
                ) : (
                  <>
                    <Row style={{ marginTop: "20px" }}>
                      <Col
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Button
                          disabled={showAnsscreen}
                          onClick={() => {
                            toggle(0);
                            checkAnswerQuestion(
                              questions[currentQuestion].QuizQuestionID,
                              questions[currentQuestion].Option1
                            );
                          }}
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            padding: "10px",
                            background: myColor(
                              0,
                              questions[currentQuestion].Option1
                            ),
                          }}
                        >
                          <img
                            style={{ width: "100px" }}
                            src={questions[currentQuestion].Option1}
                          />
                          {/* {questions[currentQuestion].Option1} */}
                        </Button>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "10px" }}>
                      <Col
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Button
                          disabled={showAnsscreen}
                          onClick={() => {
                            toggle(1);
                            checkAnswerQuestion(
                              questions[currentQuestion].QuizQuestionID,
                              questions[currentQuestion].Option2
                            );
                          }}
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            padding: "10px",
                            background: myColor(
                              1,
                              questions[currentQuestion].Option2
                            ),
                          }}
                        >
                          <img
                            style={{ width: "100px" }}
                            src={questions[currentQuestion].Option2}
                          />
                          {/* {questions[currentQuestion].Option1} */}
                        </Button>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "10px" }}>
                      <Col
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Button
                          disabled={showAnsscreen}
                          onClick={() => {
                            toggle(2);
                            checkAnswerQuestion(
                              questions[currentQuestion].QuizQuestionID,
                              questions[currentQuestion].Option3
                            );
                          }}
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            padding: "10px",
                            background: myColor(
                              2,
                              questions[currentQuestion].Option3
                            ),
                          }}
                        >
                          <img
                            style={{ width: "100px" }}
                            src={questions[currentQuestion].Option3}
                          />
                          {/* {questions[currentQuestion].Option1} */}
                        </Button>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "10px" }}>
                      <Col
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Button
                          disabled={showAnsscreen}
                          onClick={() => {
                            toggle(3);
                            checkAnswerQuestion(
                              questions[currentQuestion].QuizQuestionID,
                              questions[currentQuestion].Option4
                            );
                          }}
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            padding: "10px",
                            background: myColor(
                              3,
                              questions[currentQuestion].Option4
                            ),
                          }}
                        >
                          <img
                            style={{ width: "100px" }}
                            src={questions[currentQuestion].Option4}
                          />
                          {/* {questions[currentQuestion].Option1} */}
                        </Button>
                      </Col>
                    </Row>
                  </>
                )}
                {showAnsscreen && (
                  <div
                    style={{
                      color:
                        selectedOption &&
                          questions[currentQuestion].CorrectAnswer ===
                          selectedOption
                          ? "green"
                          : "red",
                    }}
                    className="d-flex justify-content-center mt-4 fs-4 fw-bold"
                  >
                    {selectedOption &&
                      questions[currentQuestion].CorrectAnswer === selectedOption
                      ? "🎉 Correct Answer 🎉"
                      : "😞 Wrong Answer 😞"}
                  </div>
                )}
              </>
            ) : null}
          </Modal.Body>
        </Modal >
      )}
    </>
  );
};

export default StartQuiz;
